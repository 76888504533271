/* You can add global styles to this file, and also import other style files */

body.login-page.swal2-height-auto {
    height: 100vh !important;
}

.pointer {
    cursor: pointer;
}

.backdrop-color {
    background-color: rgba(0, 0, 0, 0.4);
}

.uploadfilecontainer {
    padding: 20px;
    min-height: 200px;
    width: 100%;
    border: 2px dashed #b9b9b9;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f7f7f7;
    text-align: center;
    color: #b9b9b9;
}

.uploadfilecontainer.dragover {
    background-color: #daf5d3;
    border-color: #8ab990;
    color: #8ab990;
}

.modal-fullscreen {
    padding: 0 !important;
}

.modal-dialog.modal-fullscreen {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
}

.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
    border-radius: 0;
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
}

.modal-fullscreen .modal-body {
    overflow-y: auto;
}

.modal-dialog.modal-fullscreen .modal-content>* {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modal-dark .modal-header {
    background-color: #343a40;
    color: #eee;
}

.modal-dark .modal-footer {
    background-color: #eee;
}

.modal-dark .close {
    color: #fff;
}

.directory-menu .btn {
    text-align: left;
}

.directory-menu .btn-group {
    display: flex;
}

.directory-menu .btn-caret {
    flex: 0 0 20px;
}

.directory-menu .btn-caret:not(.collapsed) {
    /* border-bottom-right-radius: 0; */
}

.directory-menu>.nav {
    width: 100%;
}

.directory-menu .nav-item {
    margin-bottom: .5rem;
}

.directory-menu .nav-item:last-child {
    margin-bottom: 0;
}

.directory-menu .directory-submenu {
    padding: 10px;
    margin-left: 10px;
    padding-right: 0;
    margin-bottom: 1rem;
}

.directory-menu .nav-item:last-child .directory-submenu {
    margin-bottom: 0;
}

.directory-menu .btn-group>.btn:first-child {
    white-space: nowrap;
    text-overflow: ellipsis;
}

.multiselect-dropdown .dropdown-btn .selected-item {
    max-width: none !important;
    background-color: #5282b7 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 20px !important;
    padding: 4px 12px !important;
    font-size: 0.9rem;
}

.multiselect-dropdown .dropdown-btn .selected-item a {
    padding: 0 !important;
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #ffffff !important;
    border-radius: 10px !important;
    text-align: center !important;
    line-height: 1 !important;
    margin-left: 5px !important;
    font-weight: bold !important;
}

.multiselect-dropdown .dropdown-btn .selected-item a:hover {
    color: #5282b7 !important;
    background-color: #ffffff !important;
}

.login-page,
.register-page {
    background-color: #1b1b1b;
}

.login-page .card,
.login-page .card-body,
.register-page .card,
.register-page .card-body {
    border-radius: 1rem;
}

.brand-link {
    padding: 15px 25px;
}

.brand-link img {
    width: 100%;
    max-width: 80px;
}

.login-card-body,
.register-card-body {
    background-color: #5282b7;
    color: #ffffff;
    border: 3px solid #ffffff;
}

.login-card-body .btn,
.register-card-body .btn {
    border: 2px solid #fff;
    color: #ffffff;
    font-weight: bold;
}

.login-card-body .btn:hover,
.register-card-body .btn:hover {
    background-color: #ffffff;
    color: #5282b7;
}

.bg-gpp {
    background-color: #5383b8 !important;
}

.bg-gpp,
.bg-gpp>a {
    color: #fff !important;
}

.logo-size {
    background-color: 2px solid red;
}

/* .directory-submenu .nav-item::before {
    content: "\f3bf";
    font-family: "Font Awesome 5 Free";
} */


/* .directory-menu .directory-submenu .directory-submenu {
    background-color: #ddd;
}

.directory-menu .directory-submenu .directory-submenu .directory-submenu {
    background-color: #ccc;
}

.directory-menu .directory-submenu .directory-submenu .directory-submenu .directory-submenu {
    background-color: #bbb;
} */